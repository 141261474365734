@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Poppins"
}



.room-input {
	padding: 12px 20px;
	font-size: 1rem;
	border-radius: 6px;
	text-align: center;
	outline: transparent;
	width: 100%;
	border: 3px solid transparent;
	border-image: linear-gradient(190deg, #008aff, #86d472);
	border-image-slice: 1;
}

.submit-btn {
	background-image: linear-gradient(135deg, #008aff, #86d472);
	border-radius: 6px;
	box-sizing: border-box;
	color: #ffffff;
	display: block;
	height: 50px;
	font-size: 1.4em;
	font-weight: 600;
	padding: 4px;
	position: relative;
	text-decoration: none;
	width: 7em;
	z-index: 2;
	display: block;
	margin: auto;
	margin-top: 8px;
	cursor: pointer;
	outline: none;
	border: none;
}

.submit-btn:hover {
	color: #fff;
}

.submit-btn .submit-btnspan {
	align-items: center;
	background: #0e0e10;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	height: 100%;
	transition: background 0.5s ease;
	width: 100%;
}

.submit-btn:hover .submit-btnspan {
	background: transparent;
}

.chat-container {
	width: 100vw;
	height: 100vh;
	background: #f0ebeb;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

.chatbox {
	/* background: #fff; */
	background: rgba(253, 253, 255, 0.342);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(11.6px);
	-webkit-backdrop-filter: blur(11.6px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	width: 93vw;
	height: 93vh;
	min-width: 250px;
	border-radius: 10px;
	display: flex;
	position: relative;
}

.col-1 {
	overflow-y: scroll;
	flex: 1 1;
}

.cards-list {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}

.paste-card {
	border: 3px solid transparent;
	border-image: linear-gradient(190deg, #008aff, #86d472);
	border-image-slice: 1;
    padding: 20px 16px 20px 24px;
    transition: border .2s ease;
}

.paste-card:hover {
    border: 1px solid #008aff;
    background-clip: padding-box;
}

@media only screena and (max-width: 900px) {
    .cards-list {
        place-content: center !important;
    }
}

.msg-row {
	display: flex;
	align-items: center;
	margin: 40px;
}

.sign-out {
	position: absolute;
	z-index: 2;
	font-family: monospace;
}

.msg-count {
	position: absolute;
}

.sign-in-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sign-in {
	transition: background-color 0.3s, box-shadow 0.3s;
	padding: 12px 16px 12px 42px;
	border: none;
	border-radius: 3px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
	color: #757575;
	border: 2px solid #06f;
	font-size: 14px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
	background-color: white;
	background-repeat: no-repeat;
	background-position: 12px 11px;
}

.sign-in:hover {
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.msg-text {
	background: #05202e;
	padding: 13px 28px;
	border-radius: 8px;
	flex-basis: 40%;
	font-weight: 300;
	position: relative;
	word-break: break-word;
}

@media only screen and (max-width: 900px) {
	.right {
		margin-left: unset !important;
	}
	.msg-text {
		width: 100%;
		flex-basis: unset !important;
	}
}
.right {
	margin-left: 68%;
}

.msg-text h2 {
	font-weight: 400;
	font-size: 22px;
}

img {
	width: 50px;
	border-radius: 50%;
	margin: 0 20px;
}

.send {
	border: 0;
	outline: none;
	padding: 20px 40px;
	background: #05202e;
	color: white;
	width: 100%;
	font-size: 16px;
	z-index: 1;
	font-weight: 300;
	position: absolute;
	bottom: 0;
	border-radius: 10px;
}

.send::placeholder {
	color: white;
}
